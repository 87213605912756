<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>family-umbrella-protect</title>
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M2.083,12a9.927,9.927,0,0,1,19.853,0l-.716-.6a4.158,4.158,0,0,0-5.9.6A4.678,4.678,0,0,0,8.7,12a4.154,4.154,0,0,0-5.9-.6Z"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="12.009"
      y1="2.074"
      x2="12.009"
      y2="0.75"
    />
    <circle fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" cx="3.384" cy="17.142" r="2.625" />
    <circle fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" cx="20.634" cy="17.142" r="2.625" />
    <circle fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" cx="12.009" cy="17.142" r="2.625" />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M.759,21.363A4.5,4.5,0,0,1,7.524,23.25H7.87a4.5,4.5,0,0,1,8.279,0H16.5a4.5,4.5,0,0,1,6.764-1.887"
    />
  </svg>
</template>
